import { useState } from 'react';
import { useDrawer, useDrawerDispatch } from '../../contexts/drawer/drawer.provider';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { getMaxUtxo, mintToken } from '../../../utils/util';
import { addSoulbound } from '../../../services/token.service';
import LoadingDrawer from '../loading';

export default function CreateSoulToken() {
  const { cardano: { wallet }, collection } = useDrawer();
  const dispatch = useDrawerDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const placeholderObj = {
    "city": "Buenos Aires",
    "country": "Argentina",
    "course": "Cardano Developers Course",
    "date": "2024-07-29",
    "entity": "FRBA UTN",
    "hours": 50,
    "image": "ipfs://bafybeihlioutct4g64hbgzcn3vjtn367wyskwwqvyyvvmj7ohtdddtb7a4",
    "issuer": "IOG",
    "status": "Completed",
    "student": "Charles Hoskinson",
    "type": "Attendance",
    "venue": "ALBA"
  }

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [metadata, setMetadata] = useState(JSON.stringify(placeholderObj, null, 4));
  const [isMetadataValidJson, setIsMetadataValidJson] = useState(true);
  const [loading, setLoading] = useState(false);

  const closeDrawer = () => {
    dispatch({
      type: 'CLOSE_DRAWER'
    });
  };  

  const validateJsonFormat = (metadata) => {
    try {
      JSON.parse(metadata);
    } catch (e) {
      setIsMetadataValidJson(false);
      return;
    }
    setIsMetadataValidJson(true);
    return;
  }


  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    
    // mint token
    const provider = wallet.provider;

    const { collectionId, policyId, policyHash, smartContract, mint, invited } = collection;
    const utxo = getMaxUtxo(await provider.wallet.getUtxos());
    
    const addressDetails = wallet.utils.getAddressDetails(address);
    const beneficiary = addressDetails.paymentCredential.hash;
    const stakeAddress = wallet.utils.credentialToRewardAddress(addressDetails.stakeCredential);

    const _metadata = JSON.parse(metadata || '{}')
    try {
      const signatures = invited.reduce((dict, sig) => ({...dict, [sig.keyHash]: sig.signature}), {});
      const { txComplete, mintUtxo } = await mintToken(name, _metadata, policyId, policyHash, beneficiary, signatures, smartContract, mint, utxo, provider);
      // console.log(txSigned.toString());
      const txSigned = await txComplete.complete();
      console.log('cbor', txSigned.toString());

      const txHash = await txSigned.submit();
      console.log('Tx Id:', txHash);
      const success = await provider.awaitTx(txHash);
      console.log('Success?', success);

      const token = await addSoulbound(collectionId, { mintUtxo, beneficiary: address, beneficiary_stake: stakeAddress, name, metadata: _metadata });
      collection.tokens.push(token);
      closeDrawer();
      setLoading(false);
      navigate(location.pathname, { replace: true });
      
    } catch (error) {
      setLoading(false);
      console.log('Error', error);
    }
  };
  
  return (
    <div className="d-flex flex-column w-100 h-100 p-3 drawer">      
        <div className="drawer-header">
          <div className="d-flex justify-content-start"> 
            {loading ?(
              <button
              className="btn btn-close align-content-center px-1 mt-2 position-absolute cursor-loading"
              aria-label="close"
            >                        
            </button>
            ):(
              <button
              className="btn btn-close align-content-center px-1 mt-2 position-absolute"
              onClick={closeDrawer}
              aria-label="close"
            >                        
            </button>
            )}                 
            <h4 className="align-content-center text-center w-100 m-0 py-3 font-weight-semibold">
              Create SOULBOUND TOKEN
            </h4>
          </div>          
        </div>
        {loading && <LoadingDrawer/>}      
        <div className="drawer-body">
          <form
            name="myform"
            className="signin_validate row g-3"
            onSubmit={handleSubmit}
          >
            <div className="col-12">
              {/* <label className="form-label">Name</label> */}
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                name="name"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <div className="col-12">
              {/* <label className="form-label">Symbol</label> */}
              <input
                type="text"
                className="form-control"
                placeholder="addr..."
                name="address"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              />
            </div>
            <div className='col-12 mt-4 mb-2 border'></div>            
            <div className="col-12">
            <Form.Label>Metadata</Form.Label>
              <Form.Control
                as="textarea"
                placeholder={JSON.stringify(placeholderObj, null, 2)}
                style={{ height: '100px' }}
                value={metadata}  
                onChange={(event) => { setMetadata(event.target.value); validateJsonFormat(event.target.value); }}
              />
            {/* <label>
              Metadata:
              <textarea value={metadata}  onChange={(event) => setMetadata(event.target.value)} />
            </label> */}
            </div>
            {isMetadataValidJson ? <p>Valid JSON</p> : <p style={{color: "red"}}>Invalid JSON</p>}
            <div className='col-12 mt-4 mb-2 border'></div>                      
            <div className='drawer-footer'>
              {loading ? (
                <Button className="btn btn-secondary btn-block cursor-loading">
                  Create
                </Button>
              ):(
                <Button type="submit" className="btn btn-gradient btn-block">
                  Create
                </Button>
              )}
             
            </div>
          </form>
        </div>
    </div>
  );
}
